<template>
  <div></div>
</template>

<script>

  export default {
    data() {
      return {}
    },
    mounted() {},
    methods: {},
  }
</script>

<style lang="scss" scoped>
</style>
