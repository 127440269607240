<template>
  <!-- fluid for fullwidth -->
  <v-container fluid fill-height>

    <div id="mapSearch"></div>

    <v-select
      v-model="selected"
      :items="selectOptions"
      item-text="label"
      item-value="id"
      solo
      @change="panTo()"
    ></v-select>

  </v-container>
</template>

<script>
  import L from '../modules/leaflet.js'
  import 'leaflet.markercluster/dist/MarkerCluster.css'
  import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
  import 'leaflet.markercluster';
  import STATIONS from '@/jsons/stations.json'
  import AREA from '@/jsons/area.json'

  export default {
    data() {
      return {
        map: null,
        cluster: null,
        selected: 0,
      }
    },
    computed: {
      // 地域一覧
      selectOptions() {
        return _.concat([{
          id: 0,
          label: this.isJa ? '全国' : 'Nationwide'
        }], _.map(AREA.centers, (center, id) => {
          return {id: id, label: this.isJa ? center.name : center.enName }
        }))
      },
      // 日本全体
      stations4bounds() {
        return _.filter(STATIONS, station => { return station.id !== '44356' })
      },
    },
    async mounted() {
      // 地図描画（setTimeoutは本来いらないが、このタブから開かれた時対策）
      setTimeout(() => {
        this.map = L.draw({id: 'mapSearch', zoomControl: false})
        this.addMarkers()
      }, 0)
    },
    methods: {
      // マーカー追加
      addMarkers() {
        this.cluster = L.markerClusterGroup({maxClusterRadius: 30})
        _.forEach(STATIONS, station => {
          const elemsSum = _.sum(_.map(_.split(station.elems, ''), str => { return _.toNumber(str); }))
          const stationType = (elemsSum <= 2) ? 'D' : station.type
          const marker = L.marker(station.coords, {
            icon: L.divIcon({
              iconSize: 'auto',
              html: '<div>' + station.name[this.lang] + '</div>',
              className: 'icon type_' + stationType,
            })
          })
          // clickイベント
          marker.on('click', () => {
            console.log(station.id)
          })
          this.cluster.addLayer(marker)
        })
        this.map.addLayer(this.cluster)
        this.map.fitBounds(_.map(this.stations4bounds, station => { return station.coords }))
      },
      // 詳細表示
      panTo() {
        const stations = this.selected ? _.filter(STATIONS, ['centerCode', this.selected]) : STATIONS
        this.map.fitBounds(_.map(stations, station => { return station.coords }))
      }
    },
  }
</script>

<style lang="scss" scoped>
  .container {
    padding: 0;
    position: relative;
  }
  #mapSearch {
    height: 100%;
    width: 100%;
    z-index: 0;
    // markerではなくその中のdivで成形
    // （transformが使えずmarkerを中心に移動できないため）
    ::v-deep .icon {
      pointer-events: none;
      div {
        pointer-events: auto;
        white-space: nowrap;
        border-radius: 5px;
        text-transform: capitalize;
        transform: translate(-50%, -50%);
      }
    }
    ::v-deep .type_A div,
    ::v-deep .type_E div {
      background-color: #C62828;
      padding: 4px 6px;
      font-size: 1.2em;
    }
    ::v-deep .type_B div {
      background-color: #2E7D32;
      padding: 4px 6px;
      font-size: 1.2em;
    }
    ::v-deep .type_C div {
      background-color: #1565C0;
      padding: 2px 4px;
      font-size: 1.1em;
    }
    ::v-deep .type_D div {
      background-color: #455A64;
      padding: 2px 4px;
      font-size: 1.1em;
    }
  }
  .v-select {
    position: absolute;
    bottom: 8px;
    right: 16px;
    width: 150px;
    ::v-deep .v-text-field__details {
      display: none;
    }
  }
</style>