<template>
  <v-dialog
    v-model="labelDialog"
  >
    <v-card>
      <v-card-title>{{ $t('title') }}</v-card-title>
      <v-list>
        <draggable v-model="options">
          <v-list-item
            v-for="option in options"
            :key="option"
          >
            <v-list-item-icon><v-icon>drag_indicator</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-checkbox
                v-model="labels"
                :label="ITEMS[option].label[lang]"
                :value="option"
                on-icon="check_box"
                off-icon="check_box_outline_blank"
              ></v-checkbox>
            </v-list-item-content>
          </v-list-item>
        </draggable>
      </v-list>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="labelDialog = false"
        >{{ $t('dialog.no') }}</v-btn>
        <v-btn
          text
          color="primary"
          @click="save()"
        >{{ $t('dialog.yes') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { ITEMS } from '@/jsons/settings.json'
  import draggable from 'vuedraggable'

  export default {
    components: { draggable },
    data() {
      return {
        ITEMS: ITEMS,
        ALL_OPTIONS: ['temp', 'precipitation1h', 'windDirection', 'wind', 'sun1h', 'humidity', 'pressure', 'snow'],
        options: [],
        labels: [],
      }
    },
    watch: {
      labelDialog() {
        if (this.labelDialog) {
          this.labels = _.cloneDeep(this.recentList)
          this.options = _.uniq(_.concat(this.labels, this.ALL_OPTIONS))
        }
      },
    },
    methods: {
      save() {
        this.recentList = _.filter(this.options, option => { return _.includes(this.labels, option) })
        this.labelDialog = false
      },
    },
    i18n: {
      messages: {
        ja: {
          title: '表示項目編集',
        },
        en: {
          title: 'EDIT ITEMS',
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .v-dialog__content {
    ::v-deep .v-dialog {
      max-width: 320px !important;
    }
  }
  .v-list-item {
    margin-left: 16px;
  }
  .v-list-item__content {
    overflow: visible;
  }
  .v-input {
    margin: 0;
    padding: 0;
    ::v-deep .v-input__slot {
      margin: 0;
    }
    ::v-deep .v-messages {
      display: none;
    }
  }
</style>
