import axios from 'axios'
import { WIND_DIRECTION } from '@/jsons/settings.json'

// dayjs
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default class StationHourly {

  constructor(id) {
    this.id = _.toString(id) // favoriteのidが数値（後ほど検証し削除）
    this.today = {record: {}, hourly: [], latest: {}}
    this.yesterday = {record: {}, hourly: [], latest: {}}
    this.daysBefore = {record: {}, hourly: [], latest: {}}
    this.updatedAt = 0
  }

  // 今日データ更新
  async renewToday() {
    if (this.updatedAt + (1 * 60 * 1000 < dayjs().valueOf())) {
      const today = dayjs().subtract(12, 'minute').startOf('day')
      const result = await this.get(today)
      if (result) {
        this.updatedAt = dayjs().valueOf()
        this.today = Object.freeze(result)
        this.saveFile('stations', this.id, result.latest)
      }
    }
  }

  // 昨日データ更新
  async renewYesterday() {
    const yesterday = dayjs().subtract(12, 'minute').subtract(1, 'day').startOf('day')
    const fileName = this.id + '_' + yesterday.format('YYYYMMDD')
    const res = await axios.get('https://data.aroundit.net/stations/' + fileName + '.json.gz?timestamp=' + dayjs().valueOf()).catch(err => { console.log(err) })
    if (res && res.data) {
      this.yesterday = Object.freeze(res.data)
    } else {
      const result = await this.get(yesterday)
      if (result) {
        this.yesterday = Object.freeze(result)
        this.saveFile('stations', fileName, result)
      }  
    }
  }

  // 一昨日データ更新
  async renewDaysBefore() {
    const daysBefore = dayjs().subtract(12, 'minute').subtract(2, 'day').startOf('day')
    const fileName = this.id + '_' + daysBefore.format('YYYYMMDD')
    const res = await axios.get('https://data.aroundit.net/stations/' + fileName + '.json.gz?timestamp=' + dayjs().valueOf()).catch(err => { console.log(err) })
    if (res && res.data) {
      this.daysBefore = Object.freeze(res.data)
    } else {
      const result = await this.get(daysBefore)
      if (result) {
        this.daysBefore = Object.freeze(result)
        this.saveFile('stations', fileName, result)
      }
    }
  }

  // 指定日データ取得
  async get(day) {
    const minutely = await this.getMinutely(day)
    if (minutely) {
      const hourly = this.getHourly(minutely)
      const latest = this.getLatest(minutely)
      const record = this.getRecord(latest, hourly)
      return {record: record, hourly: hourly, latest: latest}
    } else {
      return null
    }
  }

  // レコード取得
  getRecord(latest, hourly) {
    const day = dayjs(latest.timestamp).startOf('day')
    const latestTime = dayjs(latest.timestamp)
    let precipitation
    if (latestTime.hour() === 23 && latestTime.minute() === 50) {
      precipitation = latest.precipitation24h
    } else {
      precipitation = _.sumBy(hourly, each => { return _.toNumber(each.precipitation1h) })
    }
    return {
      gust: {value: latest.gust, timestamp: this.getRecordTimestamp(day, latest.gustTime)},
      minTemp: {value: latest.minTemp, timestamp: this.getRecordTimestamp(day, latest.minTempTime)},
      maxTemp: {value: latest.maxTemp, timestamp: this.getRecordTimestamp(day, latest.maxTempTime)},
      precipitation: {value: precipitation}
    }
  }

  // 最新データ取得
  // 降雪量は毎時データにしか含まれないので
  getLatest(minutely) {
    const latestHourly = _.find(minutely, each => { return dayjs(each.timestamp).minute() === 0 })
    return _.assign(latestHourly, minutely[0])
  }

  // 毎時データ取得
  getHourly(minutely) {
    return _.filter(minutely, each => { return dayjs(each.timestamp).minute() === 0 })
  }

  // 毎10分データ取得
  async getMinutely(day) {
    let minutely = []
    const fileNames = this.getFileNames(day)
    for (const name of fileNames) {
      const url = 'https://www.jma.go.jp/bosai/amedas/data/point/' + this.id + '/' + name + this.getTimeParam()
      const res = await axios.get(url, {
        timeout: 5000,
        transformResponse: res => { return res },
      }).catch(err => { console.log('amedas', err) })
      if (_.has(res, 'data')) {
        const data = this.fetchRawData(res.data)
        _.forEach(data, (each, time) => {
          this.addTime(each, time)
          this.addWindDirectionName(each)
          minutely.push(each)
        })
      }
    }
    return minutely.length ? _.orderBy(minutely, ['timestamp'], ['desc']) : null
  }

  // アメダスファイル名取得
  // 0時のデータには0:00-2:50までのデータが含まれる
  // 0:00までは前日データ、1:00から当日データ扱い
  // 12分遅れ
  getFileNames(day) {
    const periodHours = [21, 18, 15, 12, 9, 6, 3, 0]
    const before12min = dayjs().subtract(12, 'minute')
    const hours = _.filter(periodHours, hour => {
      return day.hour(hour).valueOf() <= before12min.valueOf()
    })
    return _.map(hours, hour => {
      return day.format('YYYYMMDD') + '_' + _.toString(hour).padStart(2, '0') + '.json'
    })
  }

  // レコードの時間取得
  getRecordTimestamp(day, time) {
    return day.hour((time.hour + 9) % 24).minute(time.minute).format()
  }

  // 毎時データ補正
  addTime(data, time) {
    data.timestamp = dayjs(time.slice(0, 8) + 'T' + time.slice(8, 12)).valueOf()
    data.time = time
  }

  // 風向追加
  addWindDirectionName(data) {
    _.forEach(data, (value, key) => {
      if (key === 'windDirection') {
        data.windDirectionName = WIND_DIRECTION[value] || undefined
      }
    })
  }

  // 現在時間パラメータ
  getTimeParam() {
    return '?__time__=' + dayjs.utc().format('YYYYMMDDHHmm')
  }

  // 値を抽出
  fetchRawData(jsonStr) {
    const json = this.parse(jsonStr)
    _.forEach(json, datas => {
      _.forEach(datas, (value, key) => {
        datas[key] = _.isArray(value) ? _.toString(value[0]) : _.isObject(value) ? value : _.toString(value)
      })
    })
    return json
  }

  // x.0を文字列化
  parse(jsonStr) {
    jsonStr = jsonStr.replace(/([+-]?\d+\.0)/g, '"$1"')
    return JSON.parse(jsonStr)
  }

  // ファイル保存
  async saveFile(dir, name, data) {
    return await axios.post('https://data.aroundit.net/', {
      dir: dir,
      name: name + '.json.gz',
      data: JSON.stringify(data),
      compress: true,
    }, {
      timeout: 10000,
    }).catch(err => { console.log('save: ', err.message) })
  }
  
}
