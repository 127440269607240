<template>
  <div>
    <StationHourly v-if="! switchChart" :station="station" day="yesterday" />
    <StationHourlyCharts v-if="switchChart" :station="station" day="yesterday" />
    <v-switch
      v-model="switchChart"
      inset
      label=""
      hint="test"
    ></v-switch>
  </div>
</template>

<script>
  import StationHourly from '@/components/StationHourly.vue'
  import StationHourlyCharts from '@/components/StationHourlyCharts.vue'

  export default {
    components: { StationHourly, StationHourlyCharts },
    props: ['station'],
    data() {
      return {
        switchChart: false,
      }
    },
    computed: {},
    async mounted() {},
    watch: {},
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .v-input--switch {
    position: fixed;
    bottom: 120px;
    right: 8px;
    z-index: 9999;
  }
</style>
