<template>
  <fragment>
    <TheAppbar title="ダッシュボード"></TheAppbar>
    <v-main>
      <v-container>
        <v-card
          flat
        >
          <v-card-title>観測値一覧を生成</v-card-title>
          <v-card-text>
            <v-btn
              block
              large
              @click="getStations"
            ><v-icon>download</v-icon>&nbsp;stations.json</v-btn>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
  </fragment>
</template>

<script>
  import axios from 'axios'
  import TheAppbar from '@/commons/components/TheAppbar.vue'

  export default {
    components: { TheAppbar },
    data() {
      return {}
    },
    methods: {
      // stations.jsonを生成＆ダウンロード
      async getStations() {
        const res = await axios.get('https://amedas.aroundit.net/api/stations/', {
          setTimeout: 100 * 1000
        })
        if (res && res.data) {
          this.download('stations', res.data)
        }
      },
      // ダウンロード
      download(name, data) {
        const blob = new Blob([JSON.stringify(data)], {type: 'application/json'})
        const a = document.createElement('a')
        a.download = name + '.json'
        a.href = URL.createObjectURL(blob)
        a.click()
      }
    },
  }
</script>

<style lang="scss" scoped>
</style>
