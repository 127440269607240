<template>
  <fragment>
    <v-container fill-height>
      <v-subheader>{{ $t('title') }}</v-subheader>
      <v-list>
        <!-- Theme -->
        <v-list-item
          @click="setInputDialog(radioTheme)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ radioTheme.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ _.find(radioTheme.options, ['id', theme]).label }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset />
      </v-list>
    </v-container>
    <TheInputDialog
      :inputDialog="inputDialog"
      @save="saveInput"
      @cancel="inputDialog = null" />
  </fragment>
</template>

<script>
  import TheInputDialog from '@/commons/components/TheInputDialog.vue'
  export default {
    components: { TheInputDialog },
    data() {
      return {
        // 対象データ
        inputDialog: null,
        value: null,
        // テーマ
        radioTheme: {
          title: this.$t('theme.theme'),
          type: 'radio',
          key: 'theme',
          value: null,
          options: [
            {id: 'light', label: this.$t('theme.light')},
            {id: 'dark', label: this.$t('theme.dark')},
            {id: 'system', label: this.$t('theme.system')}
          ],
        },
      }
    },
    computed: {},
    mounted() {},
    methods: {
      saveInput(res) {
        this[res.key] = res.value
        this.inputDialog = null
      },
      setInputDialog(inputDialog) {
        inputDialog.value = _.get(this, inputDialog.key) || null
        this.inputDialog = _.cloneDeep(inputDialog)
      },
    },
    i18n: {
      messages: {
        ja: {
          title: '設定',
        },
        en: {
          title: 'Setting',
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
</style>

