<template>
  <fragment>
    
    <TheAppbar
      v-if="! sortting"
      :title="_.find(tabItems, ['id', tabId]).title[lang]"
    >
      <v-btn
        icon
        @click="labelDialog = true"
      ><v-icon>more_vert</v-icon></v-btn>
      <v-btn
        icon
        @click="sortting = true"
      ><v-icon>sort</v-icon></v-btn>
    </TheAppbar>

    <!-- 並び替え時 header -->
    <v-app-bar
      v-else
      app
      :height="appBarHeight"
    >
      <v-btn icon @click="sortting = false"><v-icon>close</v-icon></v-btn>
      <v-toolbar-title>{{ $t('sorting') }}</v-toolbar-title>
    </v-app-bar>

		<!-- コンテンツ -->
    <v-main>
      <v-tabs-items
        v-model="tabId"
        touchless
      >
        <v-tab-item value="tab0"><Tab0 /></v-tab-item>
        <v-tab-item value="tab1"><Tab1 /></v-tab-item>
        <v-tab-item value="tab2"><Tab2 /></v-tab-item>
        <v-tab-item value="tab3"><Tab3 /></v-tab-item>
        <v-tab-item value="tab4"><Tab4 /></v-tab-item>
      </v-tabs-items>
    </v-main>

    <TheFooter :tabItems="tabItems" />
    <LabelDialog />
    <Station />
    <DataLoader />

  </fragment>
</template>

<script>
  import TheAppbar from '@/commons/components/TheAppbar.vue'
  import TheFooter from '@/commons/components/TheFooter.vue'
  import Tab0 from '@/views/Tab0.vue'
  import Tab1 from '@/views/Tab1.vue'
  import Tab2 from '@/views/Tab2.vue'
  import Tab3 from '@/views/Tab3.vue'
  import Tab4 from '@/views/Tab4.vue'
  import Station from '@/components/Station.vue'
  import LabelDialog from '@/components/LabelDialog.vue'
  import DataLoader from '@/components/DataLoader.vue'

  export default {
    components: { TheAppbar, TheFooter, Tab0, Tab1, Tab2, Tab3, Tab4, Station, LabelDialog, DataLoader },
    data() {
      return {
        tabItems: [{
          id: 'tab0',
          title: {ja: 'お気に入り', en: 'Favorites'},
          icon: 'bookmark_border',
          scrollTop: 0,
        }, {
          id: 'tab1',
          title: {ja: '概況', en: 'Overview'},
          icon: 'satellite_alt',
          scrollTop: 0,
        }, {
          id: 'tab2',
          title: {ja: 'ランキング', en: 'Ranking'},
          icon: 'star',
          scrollTop: 0,
        }, {
          id: 'tab3',
          title: {ja: '探す', en: 'Search'},
          icon: 'search',
          scrollTop: 0,
        }, {
          id: 'tab4',
          title: {ja: '設定', en: 'Settings'},
          icon: 'settings',
          scrollTop: 0,
        }],
      }
    },
    computed: {},
    async mounted() {
      // this.tabId = 'tab1'
    },
    watch: {
      // スクロール量保存
      scrollTop(scrollTop) {
        const tab = _.find(this.tabItems, ['id', this.tabId])
        tab.scrollTop = scrollTop
      },
      // スクロール調整（タブ切り替え終了後）
      tabId(tabId) {
        setTimeout(() => {
          const tab = _.find(this.tabItems, ['id', tabId])
          window.scrollTo(0, tab.scrollTop)
        }, 0)
      }
    },
    methods: {
    },
    i18n: {
      messages: {
        ja: {
          sorting: '並び替え中',
        },
        en: {
          sorting: 'Sorting',
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
</style>
