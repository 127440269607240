<template>
  <div
    class="banner"
    :style="style"
    v-show="this.pageId === 'home' && ! this.drawer"
  >
    <Adsense
      v-if="adsense"
      data-ad-client="ca-pub-1693924705262886"
      data-ad-slot="1647164467"
      data-ad-format="horizontal"
    ></Adsense>
  </div>
</template>

<script>
  import INIT from '@/init.js'
  import { AdMob, BannerAdPosition, BannerAdPluginEvents } from '@capacitor-community/admob'
  import Vue from 'vue'
  import Ads from 'vue-google-adsense'
  Vue.use(require('vue-script2'))
  Vue.use(Ads.Adsense)

  export default {
    data() {
      return {
        adsense: false,
        bannerTimeout: null,
        savedBannerSize: {height: 0, width: 0},
        xVirtualBannerHeight: 0,
      }
    },
    computed: {
      style() {
        return {
          height: this.bannerSize.height + 'px',
        }
      },
      _renewBanner() {
        return this.banner + this.isPurchased + this.pageId + this.drawer + this.tabHeight + this.bodyWidth + this.safeArea.bottom
      },
      // 仮想バナー高さ
      virtualBannerHeight() {
        const virtualHeightByRatio = (this.bodyWidth > 468 && this.bodyHeight > 468) ? 60 * (this.bodyWidth / 468) : 50 * (this.bodyWidth / 320)
        const virtualHeightByHeight = this.bodyHeight * 0.15
        const virtualBannerHeight = _.round(_.min([virtualHeightByRatio, virtualHeightByHeight, 90]))
        if (this.isSite) {
          return (this.bodyWidth > 800) ? 90 : 100
        } if (this.platform === 'ios') {
          return this.footer ? virtualBannerHeight : virtualBannerHeight + this.safeArea.bottom
        } else {
          return virtualBannerHeight
        }
      }
    },
    watch: {
      _renewBanner: {
        immediate: true,
        async handler() {
          if (this.banner && ! this.isPurchased && this.pageId === 'home' && ! this.drawer) {
            // 表示
            if (this.bannerSize.height === 0 || this.xVirtualBannerHeight !== this.virtualBannerHeight) {
              // bannerサイズが変わる可能性がある場合に削除し空間予約変更
              this.hide()
              this.renewBannerSize('virtual')
            }
            clearTimeout(this.bannerTimeout)
            this.bannerTimeout = setTimeout(async () => {
              this.show()
            }, 100)
          } else {
            // 非表示
            this.hide()
          }
        }
      },
      async interstitial() {
        if (this.interstitial && ! this.isPurchased) {
          this.interstitial = false
          setTimeout(async () => {
            await AdMob.prepareInterstitial({
              adId: INIT.API_KEY.admob.interstitial[this.platform],
            })
            await AdMob.showInterstitial()
          }, 300)
        }
      },
    },
    async mounted() {
      await this.initialize()
    },
    methods: {
      //初期化
      async initialize() {
        this.isInitialized = true
        await AdMob.initialize({
          requestTrackingAuthorization: true,
          testingDevices: ['2077ef9a63d2b398840261c8221a0c9b'],
          initializeForTesting: true,
        })
        AdMob.addListener(BannerAdPluginEvents.SizeChanged, size => {
          this.savedBannerSize = size.height ? _.cloneDeep(size) : this.savedBannerSize
          this.renewBannerSize('real')
        })
      },
      // 表示
      async show() {
        if (this.isSite) {
          this.adsense = true
          this.bannerSize.height = (this.bodyWidth > 800) ? 90 : 100
        } else if (this.platform !== 'web') {
          this.renewBannerSize('real')
          await AdMob.showBanner({
            adId: INIT.API_KEY.admob.banner[this.platform],
            position: BannerAdPosition.BOTTOM_CENTER,
            margin: this.tabHeight,
            bottom: 0
          })
        }
      },
      // 非表示
      async hide() {
        this.renewBannerSize('real')
        if (this.isSite) {
          this.adsense = false
        } else {
          await AdMob.removeBanner()
        }
      },
      // 実際のバナーサイズ更新
      renewBannerSize(type) {
        if (this.isPurchased) {
          // 有料ユーザー
          this.bannerSize = {height: 0, with: 0}
        } else {
          // 通常
          if (type === 'virtual' || ! this.savedBannerSize.height) {
            this.bannerSize = {height: this.virtualBannerHeight, width: this.bodyWidth}
            this.xVirtualBannerHeight = this.virtualBannerHeight
          } else {
            this.bannerSize = _.cloneDeep(this.savedBannerSize)
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .banner {
    width: 100%;
    height: auto; // JS
    text-align: center;
  }
</style>
