import INIT from '@/init.js'
import { Storage } from '@capacitor/storage'
import { nanoid } from 'nanoid'

// initialize
Storage.configure({
  group: 'NativeStorage'
})

class AppData {
  constructor() {
    this.local = null
    this.native = null
    this.timeout = null
  }

  // set
  set(appData) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      localStorage.setItem(INIT.APP_ID, JSON.stringify(appData))
      Storage.set({
        key: 'backup',
        value: JSON.stringify(appData),
      })
    }, 1000)
  }

  // get
  get() {
    this.local = this.getLocalStorage()
    return this.local ? this.update(this.local) : _.cloneDeep(INIT.LOCAL_DATA_STRUCTURE)
  }

  // restore
  async restore() {
    this.native = await this.getNativeStorage()
    return this.native ? this.update(this.native) : _.cloneDeep(INIT.LOCAL_DATA_STRUCTURE)
  }

  // update
  update(storage) {
    // appDataの更新
    const initData = _.cloneDeep(INIT.LOCAL_DATA_STRUCTURE.data)
    const pickedData = _.pick(_.cloneDeep(storage.data), _.keys(INIT.LOCAL_DATA_STRUCTURE.data))
    const newData = _.assign(initData, pickedData)
    // storageの作成
    const newStorage = _.cloneDeep(storage)
    newStorage.data = newData
    // nanoid追加
    newStorage.userId = newStorage.userId || nanoid(10)
    // バージョンアップ
    newStorage.ver = INIT.VER
    return newStorage
  }

  // local storage
  getLocalStorage() {
    const storageStr = localStorage.getItem(INIT.APP_ID)
    return this.parse(storageStr)
  }

  // native storage
  // なぜか２重にstringifyされている？
  async getNativeStorage() {
    const res = await Storage.get({key: 'backup'})
    const storage1 = res ? this.parse(res.value) : null
    const storage2 = res ? this.parse(this.parse(res.value)) : null
    return storage2 || storage1
  }

  // parse to json
  parse(storageStr) {
    let storage = null
    if (storageStr) {
      try {
        storage = JSON.parse(storageStr)
      } catch(err) {
        console.log(err)
      }
    }
    return storage
  }
}

export default new AppData()
