<template>
  <v-container v-if="isReady">
    <div>{{ $dayjs(_.get(station, '[day].timestamp')).format('MM月DD日') }}</div>

    <v-card elevation="1">
      <v-card-text>
        <v-data-table
          id="record"
          :headers="recordTable.headers"
          :items="recordTable.items"
          mobile-breakpoint="300"
          disable-filtering
          disable-sort
          hide-default-footer
        >
          <!-- headers -->
          <template v-for="key in recordKeys" v-slot:[`header.${key}`]="{ header }">
            <div :key="key" v-html="header.text"></div>
          </template>
          <!-- items -->
          <template v-for="key in recordKeys" v-slot:[`item.${key}`]="{ item }">
            <div :key="key" v-html="item[key]"></div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card elevation="1">
      <v-card-text>
        <v-data-table
          id="record"
          :headers="hourlyTable.headers"
          :items="hourlyTable.items"
          mobile-breakpoint="300"
          disable-filtering
          disable-sort
          hide-default-footer
          :items-per-page="100"
        >
          <!-- headers -->
          <template v-for="key in hourlyKeys" v-slot:[`header.${key}`]="{ header }">
            <div :key="key" v-html="header.text"></div>
          </template>
          <!-- items -->
          <template v-for="key in hourlyKeys" v-slot:[`item.${key}`]="{ item }">
            <div :key="key" v-html="item[key]"></div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
<!-- 
    <v-btn
      fab
      fixed bottom right
      color="primary"
      :style="{bottom: footerHeight + 20 + 'px'}"
      @clock="openChart"
    ><v-icon>leaderboard</v-icon></v-btn>
 -->
  </v-container>
</template>

<script>
  import { ITEMS } from '@/jsons/settings.json'

  export default {
    // components: { HourlyCharts },
    props: ['station', 'day'], // today or yesterday
    data() {
      return {
      }
    },
    computed: {
      isReady() { return (this.day && _.has(this.hourly, 'length') && _.has(this.record, 'precipitation')) ? true : false },
      hourly() { return _.get(this.station, '[' + this.day + '].hourly') || null },
      record() { return _.get(this.station, '[' + this.day + '].record') || null },
      recordKeys() { return this.isReady ? _.keys(this.record) : [] },
      // hourlyKeys() { return this.isReady ? _.keys(this.hourly[0]) : [] },
      hourlyKeys() { return this.isReady ? ['time', 'temp', 'precipitation1h', 'windMulti', 'sun1h', 'humidity', 'normalPressure', 'snow1h'] : [] },
      recordTable() {
        if (this.isReady) {
          // items
          const headers = _.map(this.recordKeys, key => {
              const label = ITEMS[key].label[this.lang]
              const unit = '<br><span class="unit">[' + ITEMS[key].unit[this.lang] + ']</span>'
              return {text: label + unit, value: key, align: 'center'}
            })
          const item = {}
          _.forEach(this.recordKeys, key => {
            if (key === 'precipitation' || key === 'snow') {
              item[key] = this.record[key].value || '-'
            } else {
              item[key] = this.record[key].value + '<br>(' + this.$dayjs(this.record[key].timestamp).format('HH:mm') + ')' || '-'
            }
          })
          return {headers: headers, items: [item]}
        } else {
          return null
        }
      },
      hourlyTable() {
        if (this.isReady) {
          const headers = _.map(this.hourlyKeys, key => {
            const label = ITEMS[key].label[this.lang]
            const unit = '<br><span class="unit">[' + ITEMS[key].unit[this.lang] + ']</span>'
            return {text: label + unit, value: key, align: 'center'}
          })
          const items = []
          _.forEach(this.hourly, each => {
            const item = {}
            _.forEach(this.hourlyKeys, key => {
              if (key === 'time') {
                item[key] = this.$dayjs(each.timestamp).hour()
              } else if (key === 'windMulti') {
                item[key] = each.wind + '<br><small>' + each.windDirectionName[this.lang] + '</small>'
              } else if (key === 'normalPressure') {
                item[key] = _.round(_.toNumber(each[key]))
              } else {
                item[key] = each[key]
              }
            })
            items.push(item)
          })
          return {headers: headers, items: items}
        }
        return null
      },
    },
    async mounted() {},
    watch: {},
    methods: {
      openChart() {}
    },
  }
</script>

<style lang="scss" scoped>
  .v-btn {
    right: 24px;
    z-index: 1;
  }
</style>
