<template>
  <v-list
    dense
  >
    <template v-for="(color, index) in items.colors">
      <v-list-item
        v-if="color !== 'transparent'"
        :key="index"
      >
        <v-list-item-icon><v-icon small :color="color">circle</v-icon></v-list-item-icon>
        <v-list-item-content>{{ items.values[index] === 0 || items.values[index] ? items.values[index] : '' }}</v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
  import { OVERVIEWS } from '@/jsons/settings.json'
  export default {
    props: ['selected'],
    computed: {
      // 凡例
      items() {
        return _.find(OVERVIEWS, ['id', this.selected])
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-list {
    position: absolute;
    bottom: 16px;
    right: 8px;
    padding: 0;
    .v-list-item {
      padding: 0;
      min-height: auto;
    }
    .v-list-item__icon {
      margin: 0;
      height: 20px;
    }
    .v-list-item__content {
      padding: 0;
      font-size: 14px;
      line-height: 1;
      transform: translateY(10px);
    }
  }
</style>