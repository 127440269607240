<template>
  <v-card elevation="1" v-if="isReady">
    <v-card-text>
      <v-data-table
        :headers="dailyTable.headers"
        :items="dailyTable.items"
        :items-per-page="30"
        mobile-breakpoint="300"
        disable-filtering
        disable-sort
        hide-default-footer
      >
        <!-- headers -->
        <template v-for="key in keys" v-slot:[`header.${key}`]="{ header }">
          <div :key="key" v-html="header.text"></div>
        </template>
        <!-- items -->
        <template v-for="key in keys" v-slot:[`item.${key}`]="{ item }">
          <div :key="key" v-html="item[key]"></div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import { ITEMS } from '@/jsons/settings.json'

  export default {
    props: ['station'],
    data() {
      return {
        keys: ['time', 'precipitation24h', 'maxTemp', 'minTemp', 'maxWind', 'wind', 'sun24h', 'humidity', 'normalPressure', 'snow', 'snow24h', 'dayWeather'],
      }
    },
    computed: {
      isReady() { return this.daily.length ? true : false },
      daily() { return _.get(this.station, 'daily') ? _.reverse(_.cloneDeep(_.get(this.station, 'daily'))) : [] },
      dailyTable() {
        if (this.isReady) {
          // items
          const headers = _.map(this.keys, key => {
              const label = ITEMS[key].label[this.lang]
              const unit = ITEMS[key].unit[this.lang] ? '<br><span class="unit">[' + ITEMS[key].unit[this.lang] + ']</span>' : ''
              return {text: label + unit, value: key, align: 'center'}
            })
          const items = []
          _.forEach(this.daily, day => {
            const item = {}
            _.forEach(this.keys, key => {
              if (key === 'time') {
                item[key] = this.$dayjs(day.timestamp).format('M/D')
              // } else if (key === 'tempMulti') {
              //   item[key] = day.maxTemp + '<br>' + day.minTemp
              // } else if (key === 'windMulti') {
              //   item[key] = day.maxWind + '<br>(' + day.wind + ')'
              } else if (key === 'normalPressure') {
                item[key] = day.normalPressure ? _.round(_.toNumber(day.normalPressure)) : '-'
              } else {
                item[key] = day[key]
              }
            })
            items.push(item)
          })
          return {headers: headers, items: items}
        } else {
          return null
        }
      },
    },
    async mounted() {},
    watch: {},
    methods: {
    },
  }
</script>

<style lang="scss" scoped>
</style>
