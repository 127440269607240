<template>
  <fragment>

    <TheAppbar :title="$t('title')" />

    <v-main :style="{minHeight: this.bodyHeight + 'px'}">
      <v-container class="text">
        <div class="privacy" v-html="privacy"></div>
      </v-container>
    </v-main>

  </fragment>
</template>

<script>
  import axios from 'axios'
  import TheAppbar from '@/commons/components/TheAppbar.vue'

  export default {
    data() {
      return {
        privacy: '',
      }
    },
    async mounted() {
      const res = await axios.get(this.INIT.URLS.privacy).catch(error => { console.log(error) })
      if (_.has(res, 'data.content.rendered')) {
        this.privacy = res.data.content.rendered
      }
    },
    i18n: {
      messages: {
        ja: {
          title: 'プライバシーポリシー',
        },
        en: {
          title: 'Privacy Policy',
        }
      }
    },
    components: { TheAppbar },
  }
</script>

<style scoped>
  .privacy>>>h2 {
    font-size: 1.2rem;
    margin: 50px 0 20px;
  }
  .privacy>>>li {
    margin-bottom: 10px;
  }
</style>
