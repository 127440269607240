<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
  >
    <v-card v-if="station">
      <v-app-bar
        fixed
        :height="appBarHeight"
      >
        <v-btn icon @click="stationId = null"><v-icon>close</v-icon></v-btn>
        <v-toolbar-title>{{ station.name[lang] }}</v-toolbar-title>
      </v-app-bar>
      <v-main>
        <v-tabs-items
          v-model="stationTabId"
          touchless
        >
          <v-tab-item value="stationTab0"><Tab0 :station="station" /></v-tab-item>
          <v-tab-item value="stationTab1"><Tab1 :station="station" /></v-tab-item>
          <v-tab-item value="stationTab2"><Tab2 :station="station" /></v-tab-item>
          <v-tab-item value="stationTab3"><Tab3 :station="station" /></v-tab-item>
          <v-tab-item value="stationTab4"><Tab4 :station="station" /></v-tab-item>
        </v-tabs-items>
      </v-main>
      <StationFooter :tabItems="tabItems" />
    </v-card>
  </v-dialog>
</template>

<script>
  import StationFooter from '@/components/StationFooter.vue'
  import Station from '@/modules/station.js'
  import Tab0 from '@/components/StationTab0.vue'
  import Tab1 from '@/components/StationTab1.vue'
  import Tab2 from '@/components/StationTab2.vue'
  import Tab3 from '@/components/StationTab3.vue'
  import Tab4 from '@/components/StationTab4.vue'

  export default {
    components: { Tab0, Tab1, Tab2, Tab3, Tab4, StationFooter },
    data() {
      return {
        station: null,
        tabItems: [{
          id: 'stationTab0',
          title: {ja: '今日', en: 'Today'},
          icon: 'today',
          scrollTop: 0,
        }, {
          id: 'stationTab1',
          title: {ja: '昨日', en: 'Yesterday'},
          icon: 'event_repeat',
          scrollTop: 0,
        }, {
          id: 'stationTab2',
          title: {ja: '過去', en: 'History'},
          icon: 'history',
          scrollTop: 0,
        }, {
          id: 'stationTab3',
          title: {ja: '予報', en: 'Forecast'},
          icon: 'sunny',
          scrollTop: 0,
        }, {
          id: 'stationTab4',
          title: {ja: '情報', en: 'Info'},
          icon: 'info_outline',
          scrollTop: 0,
        }],
      }
    },
    computed: {
      dialog() { return this.station ? true : false}
    },
    async mounted() {
      this.stationTabId = 'stationTab2'
    },
    watch: {
      async stationId() {
        if (this.stationId) {
          this.station = new Station(this.stationId, this.overview)
          await this.station.renewToday()
          await this.station.renewYesterday()
          await this.station.renewDaysBefore()
          await this.station.renewForecast()
          await this.station.renewDaily()
          console.log(this.station)
        } else {
          this.station = null
        }
      }
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .v-toolbar {
    ::v-deep .v-toolbar__content {
      padding-top: var(--safe-area-top);
    }
  }
</style>
