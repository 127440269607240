<template>
  <div></div>
</template>

<script>
  import { StatusBar, Style } from '@capacitor/status-bar'
  import safeAreaInsets from 'safe-area-insets'
  import SafeArea from '@/commons/modules/safeArea.js'
  import AppData from '@/commons/modules/appData.js'
  import Purchase from '@/commons/modules/purchase.js'
  import Analytics from '@/commons/modules/analytics.js'
  import Firestore from '@/commons/modules/firestore'
  import vuetify from '@/commons/modules/vuetify'

  export default{
    data() {
      return {
        safeAreaTimeout: null
      }
    },
    async created() {
      // resize
      this.setBodySize()
      window.addEventListener('resize', _.debounce(() => {
        this.setBodySize()
      }, 200))
      // scroll
      window.addEventListener('scroll', _.debounce(() => {
        this.scrollTop = window.pageYOffset
      }, 200))
      // storage
      let storage = AppData.get()
      this.$store.commit('setStorage', _.cloneDeep(storage))
      if (! AppData.local) {
        // restore
        storage = await AppData.restore()
        this.$store.commit('setStorage', _.cloneDeep(storage))
      }
      // title
      document.title = this.$t('appTitle')
      // safe area
      this.renewSafeArea()
      safeAreaInsets.onChange(() => { this.renewSafeArea() })
      document.addEventListener('deviceready', () => { this.renewSafeArea() })
      window.addEventListener('resize', _.debounce(() => { this.renewSafeArea() }, 200))
      // Purchase
      if (this.platform !== 'web') {
        document.addEventListener('deviceready', () => {
          Purchase.initialize(tickets => {
            this.tickets = tickets
            // ready: すでにtrueかもしれない
            this.isPurchased = _.isNull(this.isPurchased) ? false : this.isPurchased
          }, () => {
            // owned: こっちが先に実行される可能性
            this.isPurchased = true
          })
        })
      } else {
        this.isPurchased = false
      }
      // Analytics
      Analytics.initialize(this)
      // Save use data
      Firestore.setUser(storage)
      // statusbar
      document.addEventListener('deviceready', () => {
        if (this.isApp) {
          StatusBar.setOverlaysWebView({ overlay: true })
        }
      })
    },
    mounted() {},
    watch: {
      // Statubbar for dark/light mode (capacitor)
      isThemeDark: {
        immediate: true,
        handler() {
          if (this.isApp) {
            StatusBar.setStyle({ style: this.isThemeDark ? Style.Dark : Style.Light })
            StatusBar.setBackgroundColor({
              color: this.isThemeDark ? this.INIT.COLOR.dark.statusBar : this.INIT.COLOR.light.statusBar
            })
            StatusBar.setOverlaysWebView({ overlay: true })
          }
          vuetify.framework.theme.dark = this.isThemeDark // vuetify theme 切り替え
        }
      },
      // analytics
      pageId() {
        Analytics.setScreenName({
          screenName: this.pageId,
          nameOverride: _.capitalize(this.pageId),
        })
      },
    },
    methods: {
      setBodySize() {
        this.bodyWidth = window.innerWidth
        this.bodyHeight = window.innerHeight
        document.documentElement.style.setProperty('--body-height', this.bodyHeight + 'px')
      },
      // safe area
      renewSafeArea() {
        clearTimeout(this.safeAreaTimeout)
        this.safeAreaTimeout = setTimeout(async () => {
          this.safeArea = await SafeArea.get()
          this.appBarHeight = 58 + this.safeArea.top
          SafeArea.setCss()
        }, 200)
      }
    }
  }
</script>
