<template>
  <v-container
    v-if="isReady"
    :style="{paddingBottom: footerHeight + 50 + 'px'}"
  >
    <div>{{ $dayjs(_.get(station, '[day].timestamp')).format('MM月DD日') }}</div>

    <v-card
      class="time"
      elevation="1"
      color="background"
    >
      <v-card-text>
        <GChart
          type="LineChart"
          :data="chartDataTime"
          :options="optionsTime"
        ></GChart>
      </v-card-text>
    </v-card>

    <Chart :station="station" :day="day" item="temp" type="LineChart" />

    <Chart :station="station" :day="day" item="precipitation1h" type="ColumnChart" />

    <Chart :station="station" :day="day" item="sun1h" type="ColumnChart" />

    <Chart :station="station" :day="day" item="wind" type="LineChart" />

    <Chart :station="station" :day="day" item="humidity" type="ColumnChart" />

    <Chart :station="station" :day="day" item="normalPressure" type="LineChart" />

  </v-container>
</template>

<script>
  import { CHART_OPTIONS } from '@/jsons/settings.json'
  import { GChart } from 'vue-google-charts/legacy'
  import Chart from '@/components/StationHourlyChart.vue'

  export default {
    components: { GChart, Chart },
    props: ['station', 'day'], // today or yesterday
    data() {
      return {
        optionsTime: _.assign({}, CHART_OPTIONS.common, {
          height: 60,
          fontSize: 16,
          hAxis: {slantedText: false, textStyle: {color: this.isThemeDark ? '#fff' : '#aaa'}},
          chartArea: {left: '1%', width: '98%', top: '0', height: 0},
        }),
      }
    },
    computed: {
      isReady() { return (this.day && _.has(this.hourly, 'length') && _.has(this.hourlyBefore, 'length')) ? true : false },
      hourly() { return this.station[this.day] ? _.reverse(_.cloneDeep(this.station[this.day].hourly)) : null },
      hourlyBefore() {return this.station.daysBefore ? _.reverse(_.cloneDeep(this.station.daysBefore.hourly)) : null},
      // data
      chartDataTime() {
        if (this.isReady) {
          const header = ['時刻', '値']
          return _.concat([header], _.map(this.hourly, each => {
            return [this.$dayjs(each.timestamp).hour() + '時', 0]
          }))
        } else {
          return []
        }
      },
    },
    async mounted() {
    },
    watch: {},
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .v-card__text {
    padding: 0;
  }
  .time {
    position: sticky;
    top: 0;
    z-index: 3;
    padding: 8px 0;
  }
</style>

<style lang="scss">
</style>
