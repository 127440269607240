import axios from 'axios'
import dayjs from 'dayjs'
import STATIONS from '@/jsons/stations.json'
import StationHourly from '@/modules/stationHourly.js'
import { _ } from 'core-js'

export default class Station extends StationHourly {

  constructor(id, overview) {
    super(id)
    this.id = _.toString(id) // favoriteのidが数値（後ほど検証し削除）
    this.latest = {}
    this.daily = []
    this.forecast = []
    // 観測値情報を追加
    _.forEach(_.find(STATIONS, ['id', this.id]), (value, key) => {
      this[key] = value
    })
    // 最新データ更新
    this.renewLatest(overview)
  }

  // 最新データ更新
  renewLatest(overview) {
    const data = _.find(overview, ['id', this.id])
    this.addWindDirectionName(data)
    this.latest = Object.freeze(_.cloneDeep(data)) || this.latest
  }

  // 毎日データ更新
  async renewDaily() {
    const yesterday = dayjs().subtract(1, 'hour').subtract(1, 'days').startOf('day')
    const towYearAgo = dayjs(yesterday.valueOf()).subtract(2, 'year').startOf('year')
    let daily = []
    for (const index of _.range(3)) {
      const year = dayjs(towYearAgo.valueOf()).add(index, 'year')
      const endOfYear = dayjs(year.valueOf()).endOf('year').startOf('day')
      const fileName = this.id + '_' + year.year()
      const resFile = await axios.get('https://data.aroundit.net/daily/' + fileName + '?timestamp=' + dayjs().valueOf())
      if (resFile && resFile.data) {
        const timestamps = _.map(resFile.data, 'timestamp')
        const isLatest = _.includes(timestamps, endOfYear.valueOf()) || _.includes(timestamps, yesterday.valueOf())
        if (isLatest) {
          daily = _.concat(daily, resFile.data)
        } else {
          const resApi = await axios.get('https://amedas.aroundit.net/api/daily/?id=' + this.id + '&year=' + year.year())
          if (resApi && resApi.data) {
            daily = _.concat(daily, resApi.data)
          }
        }
      }
    }
    if (daily.length) {
      this.daily = Object.freeze(daily)
    }
  }

  // 予報データ更新
  async renewForecast() {
    const res = await axios.get('https://data.aroundit.net/caches/forecast.json.gz?timestamp=' + dayjs().valueOf()).catch(err => {console.log(err)})
    if (res && res.data) {
      this.forecast = Object.freeze(_.get(_.find(res.data, ['areaCode', this.areaCode]), 'forecasts'))
    }
  }

  // 過去データのページURL
  getDailyURL(year, month) {
    if (year && month) {
      const targetMonth = dayjs().subtract(1, 'days').startOf('month')
      const stationType = (this.info.type === 'A') ? 's1' : 'a1'
      return 'https://www.data.jma.go.jp/obd/stats/etrn/view/daily_' + stationType + '.php?prec_no=' + this.info.precNo + '&block_no=' + this.info.blockNo + '&year=' + targetMonth.year() + '&month=' + (targetMonth.month() + 1)
    } else {
      return null
    }
  }

}
