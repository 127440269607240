<template>
  <v-card
    :id="'chart-' + item"
    elevation="1"
  >
    <v-card-title>{{ items[item].label[lang] }}</v-card-title>
    <v-card-text>
      <GChart
        :type="type"
        :data="chartData"
        :options="options"
        @ready="ready"
      ></GChart>
      <GChart
        class="day-before"
        type="AreaChart"
        :data="chartDataBefore"
        :options="optionsBefore"
      ></GChart>
    </v-card-text>
  </v-card>
</template>

<script>
  import { ITEMS, CHART_OPTIONS } from '@/jsons/settings.json'
  import { GChart } from 'vue-google-charts/legacy'

  export default {
    components: { GChart },
    props: ['station', 'day', 'item', 'type'], // today, yesterday
    data() {
      return {
        items: ITEMS
      }
    },
    computed: {
      isReady() { return (this.day && _.has(this.hourly, 'length') && _.has(this.hourlyBefore, 'length')) ? true : false },
      hourly() {
        if (this.station[this.day]) {
          _.forEach(this.station[this.day].hourly, each => {
            each[this.item] = _.toNumber(each[this.item])
          })
          return _.reverse(_.cloneDeep(this.station[this.day].hourly))
        } else {
          return []
        }
      },
      hourlyBefore() {
        if (this.station.daysBefore) {
          _.forEach(this.station.daysBefore.hourly, each => {
            each[this.item] = _.toNumber(each[this.item])
          })
          return _.reverse(_.cloneDeep(this.station.daysBefore.hourly))
        } else {
          return []
        }
      },
      max() {
        // const plus = (this.item === 'wind') ? .5 : 1
        if (this.item === 'sun1h') {
          return 1
        } else if (this.item === 'humidity') {
          return 100
        } else {
          return _.max(_.concat(_.map(this.hourly, this.item), _.map(this.hourlyBefore, this.item))) + 1
        }
      },
      min() {
        if (_.includes(['temp', 'normalPressure'], this.item)) {
          return _.min(_.concat(_.map(this.hourly, this.item), _.map(this.hourlyBefore, this.item))) - 1
        } else {
          return 0
        }
      },
      // options
      options() {
        let option = {}
        if (this.item === 'sun1h') {
          option = {colors: ['#E65100']}
        }
        return _.assign({}, CHART_OPTIONS.common, {
          vAxis: {
            maxValue: this.max,
            minValue: this.min,
            textPosition: 'in',
            textStyle: {color: this.isThemeDark ? '#fff' : '#aaa'},
            minorGridlines: {color: this.isThemeDark ? '#111' : '#aaa'},
            gridlines: {color: this.isThemeDark ? '#333' : '#aaa'},
          },
        }, option)
      },
      optionsBefore() {
        return _.assign({}, CHART_OPTIONS.common, {
          colors: ['#555'],
          chartArea: {left: '3%', width: '94%', top: '0', height: '98%'},
          vAxis: {
            maxValue: this.max,
            minValue: this.min,
            textPosition: 'none',
            gridlines: {color: this.isThemeDark ? '#ff0000' : '#aaa'},
          },
        })
      },
      // data
      chartData() {
        if (this.isReady) {
          const header = ['時刻', this.items[this.item].label[this.lang], { role: 'annotation' }]
          return _.concat([header], _.map(this.hourly, (each, index) => {
            const time = this.$dayjs(each.timestamp).hour() + '時'
            const value = (this.item === 'normalPressure') ? _.round(each[this.item]) : each[this.item]
            const annotation = (index !== 0 && (index % 3) === 0) ? value : null
            return [time, value, annotation]
          }))
        } else {
          return []
        }
      },
      chartDataBefore() {
        if (this.isReady) {
          const header = ['時刻', this.items[this.item].label[this.lang], {type: 'string', role: 'style'}]
          return _.concat([header], _.map(this.hourlyBefore, each => {
            const time = this.$dayjs(each.timestamp).hour() + '時'
            const value = each[this.item]
            return [time, value, 'stroke-width: 0']
          }))
        } else {
          return []
        }
      },
    },
    async mounted() {
    },
    watch: {},
    methods: {
      ready() {
        if (this.item === 'wind') {
          setTimeout(() => {
            const area = document.querySelector('#chart-' + this.item + ' svg g>rect')
            const areaWidth = area ? _.toNumber(area.getAttribute('width')) : 0
            // const areaHeight = area ? _.toNumber(area.getAttribute('height')) : 0
            const container = document.querySelector('#chart-' + this.item + ' .v-card__text div')
            const eachWidth = areaWidth / (this.hourly.length / 3)
            let insertedIndex = 1
            _.forEach(this.hourly, (each, index) => {
              if (index !== 0 && index % 3 === 0) {
                const span = document.createElement('span')
                span.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffffff"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z"/></svg>'
                span.className = 'wind-direction'
                span.style.top = '5px'
                span.style.left = insertedIndex * eachWidth + 'px'
                span.style.transform = 'rotate(' + (180 + (22.5 * each.windDirection)) + 'deg)'
                container.appendChild(span)
                insertedIndex++
              }
            })
          }, 1000)
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .v-card__text {
    position: relative;
    padding: 0;
    >div {
      position: relative;
      z-index: 1;
    }
    .day-before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 0;
    }
  }
</style>

<style lang="scss">
  .wind-direction {
    position: absolute;
  }
</style>
