<template>
  <v-container>
    <template v-if="_.has(rankings, '[0].timestamp')">

      <v-select
        v-model="area"
        :items="areaOptions"
        item-text="label"
        item-value="area"
        filled
      ></v-select>

      <p v-if="isJa" class="text-right">{{ $dayjs(rankings[0].timestamp).format('M月D日 H:mm') }} <span>現在</span></p>
      <p v-else class="text-right">{{ $dayjs(rankings[0].timestamp).format('YYYY/MM/DD HH:mm') }}</p>

      <v-card
        v-for="(data, key) in rankingTable"
        :key="key"
      >
        <v-card-title>{{ $t(key) }}</v-card-title>
        <v-data-table
          :headers="data.headers"
          :items="data.items"
          :items-per-page="(open[key]) ? 20 : 5"
          mobile-breakpoint="300"
          disable-filtering
          disable-sort
          hide-default-footer
          hide-default-header
          @click:row="showDetail(data.id)"
        >
          <template v-slot:footer>
            <v-divider></v-divider>
              <v-btn
                icon block large
                color="primary"
                @click="open[key] = ! open[key]"
              >
                <v-icon v-show="! open[key]">expand_more</v-icon>
                <v-icon v-show="open[key]">expand_less</v-icon>
              </v-btn>
          </template>
        </v-data-table>
      </v-card>

    </template>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        ITEMS: ['maxTemp', 'minTemp', 'precipitation1h', 'precipitation24h', 'gust'],
        area: 'japan',
        open: {maxTemp: false, minTemp: false, precipitation1h: false, precipitation24h: false, gust: false}, // 展開用
      }
    },
    computed: {
      areaOptions() {
        return _.map(this.rankings, ranking => { return {area: ranking.area, label: ranking.name[this.lang]} })
      },
      rankingTable() {
        const rankingTable = {}
        const target = _.find(this.rankings, ['area', this.area])
        const isJapan = target.area === 'japan' ? true : false
        _.forEach(target, (ranking, key) => {
          if (_.includes(this.ITEMS, key)) {
            const items = _.map(ranking, (each, index) => {
              return {
                rank: isJapan ? each.rank : index + 1,
                prefecture: each.prefecture[this.lang],
                city: each.city[this.lang],
                value: each.value,
                timestamp: this.$dayjs(each.timestamp).format('HH:mm'),
              }
            })
            const headers = _.map(_.keys(items[0]), key => {
              return {text: '', value: key, align: 'center'}
            })
            rankingTable[key] = { headers, items }
          }
        })
        return rankingTable
      },
    },
    methods: {
      showDetail(id) {
        console.log(id)
      }
    },
    i18n: {
      messages: {
        ja: {
          maxTemp: '最高気温',
          minTemp: '最低気温',
          precipitation1h: '１時間降水量',
          precipitation24h: '24時間降水量',
          gust: '最大瞬間風速',
        },
        en: {
          maxTemp: 'Highest Temperature',
          minTemp: 'Lowest Temperature',
          precipitation1h: '1 hour precipitation',
          precipitation24h: '24 hour precipitation',
          gust: 'Max instantaneous wind speed',
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .v-card {
    margin-bottom: 16px;
  }
</style>

