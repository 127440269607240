<template>
  <div></div>
</template>

<script>
  export default {
    data() {
      return {
        interstitialRatio: 6,
        nextInterstitial: 0,
        interstitialTimeout: null,
      }
    },
    async mounted() {
      this.banner = true
    },
    computed: {},
    watch: {
      fullDialog() {
        if (this.fullDialog) {
          this.banner = false
        } else {
          this.banner = true
        }
      }
    },
    methods: {
      tryInterstitial() {
        clearTimeout(this.interstitialTimeout)
        this.interstitialTimeout = setTimeout(() => {
          if (this.nextInterstitial < this.$dayjs().valueOf()) {
            if (_.random(this.interstitialRatio - 1) === 0) {
              this.interstitial = true
              this.nextInterstitial = this.$dayjs().valueOf() + (10 * 60 * 1000)
            }
          }
        }, 500)
      },
      tryBanner() {
        this.banner = false
        setTimeout(() => {
          // if () {
            this.banner = true
          // }
        }, 100)
      }
    }
  }
</script>

