<template>
  <div></div>
</template>

<script>
  import { OVERVIEWS } from '@/jsons/settings.json'
  import L from '@/modules/leaflet.js'

  export default {
    props: ['map', 'selected', 'zoom', 'timestamp', 'bounds', 'isPlaying'],
    data() {
      return {
        markerGroups: [],
        layerIndex: 0,
        markerCanvas: null,
      }
    },
    computed: {
      _renewAllMarkers() {
        return this.map + this.selected + this.zoom
      }
    },
    watch: {
      _renewAllMarkers() {
        this.renewAllMarkers()
      },
      // 概況一覧変更
      overviews() {
        // timestamp変更後
        setTimeout(() => {
          this.renewAllMarkers()
        }, 0)
      },
      // 再生開始
      isPlaying() {
        if (this.isPlaying) {
          this.renewAllMarkers()
        }
      },
      // 時刻変更
      timestamp() {
        const xLayerIndex = this.layerIndex
        this.addMarkers()
        setTimeout(() => {
          if (this.map && this.markerGroups.length) {
            this.map.removeLayer(this.markerGroups[xLayerIndex])
          }
        }, 0)
      },
    },
    methods: {
      // 全マーカー更新
      renewAllMarkers() {
        if (this.markerGroups.length) {
          this.map.removeLayers(this.markerGroups)
        }
        this.prepareMarkers()
        this.addMarkers()
      },
      // マーカーの用意
      prepareMarkers() {
        if (this.overviews.length) {
          const type = this.zoom < 8 ? 'icon' : 'text'
          const radius = this.zoom <= 4 ? 1.5 : 2 * (this.zoom - 4)
          const setting = _.find(OVERVIEWS, ['id', this.selected])
          const markerGroups = []
          _.forEach(this.overviews, overview => {
            const markers = []
            const filteredOverview = _.filter(overview.overview, station => {
              if (this.isPlaying && type === 'text') {
                // text
                return station[this.selected]
                      && station.coords.lat <= this.bounds.lat[0] && station.coords.lat >= this.bounds.lat[1]
                      && station.coords.lng <= this.bounds.lng[0] && station.coords.lng >= this.bounds.lng[1]
              } else {
                // icon
                return station[this.selected]
              }
            })
            _.forEach(filteredOverview, station => {
              const value = _.toNumber(station[this.selected])
              const colorIndex = _.findIndex(setting.values, indexValue => {
                return value > indexValue
              })
              let marker = null
              if (this.selected === 'wind') {
                // wind
                marker = new L.ArrowMarker(station.coords, {
                  radius: radius * 4,
                  stroke: false,
                  fillColor: colorIndex >= 0 ? setting.colors[colorIndex] : setting.colors[setting.colors.length - 1],
                  fillOpacity: 1,
                  rotate: 180 + (_.toNumber(station.windDirection) * 22.5),
                })
              } else if (type === 'icon') {
                // icon
                marker = L.circleMarker(station.coords, {
                  radius: radius,
                  stroke: false,
                  fillColor: colorIndex >= 0 ? setting.colors[colorIndex] : setting.colors[setting.colors.length - 1],
                  fillOpacity: 1,
                })
              } else {
                // text
                const style = 'style="color: ' + setting.colors[colorIndex] + '"'
                marker = L.marker(station.coords, {
                  icon: L.divIcon({
                    iconAnchor: [40, 20],
                    iconSize: [80, 40],
                    html: '<div ' + style + '>' + station[this.selected] + '<br><span class="name">' + station.name[this.lang] + '</span></div>',
                    className: type,
                  })
                })
              }
              markers.push(marker)
            })
            markerGroups.push(L.layerGroup(markers))
          })
          this.markerGroups = Object.freeze(markerGroups)
        }
      },
      // マーカー追加
      addMarkers() {
        if (this.map && this.markerGroups.length && this.timestamp) {
          this.layerIndex = _.findIndex(this.overviews, ['timestamp', this.timestamp])
          this.map.addLayer(this.markerGroups[this.layerIndex])
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  #mapOverview {
    ::v-deep .text {
      font-weight: bold;
      font-size: 16px;
      line-height: 1;
      text-shadow: 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000;
      background-color: transparent;
      border: none;
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .name {
        font-size: 12px;
        color: #000;
        text-shadow: 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff;
      }
    }
  }
</style>