// global
import 'lodash'

// import
import 'material-icons/iconfont/material-icons.css'
import Vue from 'vue'
import App from './App.vue'
import router from './modules/router'
import Store from './modules/store'
import vuetify from './commons/modules/vuetify'
import i18n from './modules/i18n'
import './modules/mixin'

// plugins

// dayjs
import dayjs from 'dayjs'
Vue.prototype.$dayjs = dayjs

// fragment（enable multi root component）
// vue-fragment@1.5.1
import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

new Vue({
  router,
  store: Store,
  vuetify,
  i18n,
  render: h => h(App),
  created() {
    // 初期タブ
    // this.tabId = 'tab2'
  },
  async mounted() {
    // banner
    this.banner = true
  },
  watch: {}
}).$mount('#app')

Vue.config.productionTip = false
