// common store
import AppData from '@/commons/modules/appData.js'

export default {
  state: {
    storage: {}, // 全保存データ
    // size
    bodyWidth: window.innerWidth,
    bodyHeight: window.innerHeight,
    safeArea: {top: 0, bottom: 0, right: 0, left: 0},
    // page&tabs
    pageId: 'home',
    drawer: false,
    tabId: 'tab0',
    // header&footer
    appBarHeight: 56,
    footer: true,
    footerHeight: 0,
    tabHeight: 0,
    // ads
    banner: false,
    interstitial: false,
    bannerSize: {height: 0, width: 0},
    // purchase
    tickets: null,
    isPurchased: null, // boolだが初期値null
    // others
    pullto: 'ready', // null, ready, pulling, loading, done
    scrollTop: 0,
    isLoadings: false, // add 's' for duplicate
  },
  mutations: {
    // web storage
    setStorage(state, obj) {
      state.storage = obj
      AppData.set(obj)
    },
  },
}