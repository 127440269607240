import Vue from 'vue'
import VueI18n from 'vue-i18n'
import _i18n from '../commons/modules/_i18n'
import _mixin from '../commons/modules/_mixin'

Vue.use(VueI18n)

export default new VueI18n({
  locale: _mixin.computed.lang(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: true,
  messages: {
    ja: Object.assign(_i18n.ja, {
      appName: 'アメダス Viewer',
      appTitle: 'アメダス Viewer | 気象データ閲覧アプリ',
      appDesc: '気象データ閲覧アプリ',
      }),
    en: Object.assign(_i18n.en, {
      appName: 'Weather Observation JAPAN',
      appTitle: 'Weather Observation JAPAN',
      appDesc: 'Weather observation data viewing app',
      }),
  }
})
