<template>
  <v-container
    :style="{paddingBottom: '100px'}"
  >
    <v-card
      v-show="! sortting"
      class="header"
      color="background"
    >
      <v-card-text class="d-flex flex-row justify-space-between">
        <span
          v-for="key in labels"
          :key="key"
          :class="['label', key]"
        >{{ ITEMS[key].label[lang] }}<br><small class="unit">[{{ ITEMS[key].unit[lang] }}]</small></span>
      </v-card-text>
    </v-card>

    <draggable v-model="myStations" :disabled="! sortting">
      <v-card
        v-for="station in myStations"
        :key="station.id"
        class="item"
        :elevation="sortting ? 4 : 2"
        @click="showDetail(station)"
      >
        <v-card-title>{{ station.name[lang] }} <span class="kana" v-if="isJa">{{ station.name['kana'] }}</span></v-card-title>
        <v-card-text class="d-flex flex-row justify-space-between">
          <span
            v-for="key in labels"
            :key="key"
            :class="['value', key]"
          >
            <span class="value" v-if="station.latest">{{ fetchValue(station.latest, key) }}</span>
          </span>
        </v-card-text>
      </v-card>
    </draggable>
  </v-container>
</template>

<script>
  import { ITEMS } from '@/jsons/settings.json'
  import Station from '@/modules/station.js'
  import draggable from 'vuedraggable'
  // import dayjs from 'dayjs'

  export default {
    components: { draggable },
    data() {
      return {
        ITEMS: ITEMS,
        myStations: [],
      }
    },
    computed: {
      labels() {
        return this.recentList
      },
    },
    watch: {
      // 並び替え終了保存
      sortting() {
        if (! this.sortting) {
          this.favorites = _.map(this.myStations, 'id')
        }
      },
      // 観測値一覧生成
      favorites: {
        immediate: true,
        async handler() {
          this.myStations = []
          // 基本情報追加
          _.forEach(this.favorites, id => {
            const station = new Station(id, this.overview)
            this.myStations.push(station)
          })
          if (this.overview.length) {
            this.fixWidth()
          }
        }
      },
      overview() {
        _.forEach(this.myStations, station => {
          station.renewLatest(this.overview)
        })
        this.fixWidth()
      },
      tabId() {
        this.fixWidth()
      }
    },
    methods: {
      fetchValue(latest, key) {
        if (key === 'pressure') {
          // 気圧
          return _.round(latest[key]) || '-'
        } else if (key === 'windDirection') {
          // 風向
          return _.get(latest, 'windDirectionName.' + this.lang) || '-'
        } else {
          // その他
          return _.isUndefined(latest[key]) || _.isNull(latest[key]) ? '-' : latest[key]
        }
      },
      fixWidth() {
        setTimeout(() => {
          _.forEach(this.labels, key => {
            const spans = document.querySelectorAll('.' + key)
            // 初期化
            _.forEach(spans, span => {
              span.style.width = 'auto'
            })
            // 幅計算＆強制
            setTimeout(() => {
              const maxWidth = _.max(_.map(spans, span => { return _.ceil(span.offsetWidth) }))
              _.forEach(spans, span => {
                span.style.width = maxWidth + 1 + 'px'
              })
            }, 0)
          })
        }, 0)
      },
      async showDetail(station) {
        this.stationId = station.id
      }
    },
    mounted() {
      setTimeout(() => {
        // this.stationId = '62078'
      }, 2000)
    },
    i18n: {
      messages: {
        ja: {
          stationName: '観測地点名',
          kana: '仮名'
        },
        en: {
          stationName: 'Place',
          kana: 'kana',
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .container {
    position: relative;
  }
  .v-card {
    margin-bottom: 8px;
  }
  // .header {
  //   position: sticky;
  //   top: 60px;
  //   z-index: 1;
  // }
  .label,
  .value {
    text-align: center;
  }
  .value {
    font-size: 1.1em;
    &.windDirection {
      font-size: .9em;
    }
  }
</style>
