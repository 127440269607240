<template>
  <!-- fluid for fullwidth -->
  <v-container fluid fill-height>

    <div id="mapOverview"></div>
    <Tab1Markers :map="map" :selected="selected" :zoom="zoom" :timestamp="timestamp" :bounds="bounds" :isPlaying="isPlaying" />
    <Tab1Legend :selected="selected" />

    <v-select
      v-model="selected"
      :items="selectOptions"
      item-text="label"
      item-value="id"
      solo
    ></v-select>

    <v-slider
      v-if="overviews.length && timestamp"
      v-model="timestamp"
      :max="overviews[overviews.length - 1].timestamp"
      :min="overviews[0].timestamp"
      thumb-label="always"
      :thumb-size="48"
      step="600000"
    >
      <template v-slot:thumb-label="{ value }">
        <span class="date">{{ $dayjs(value).format('DD日') }}</span><br>
        <span class="time">{{ $dayjs(value).format('HH:mm') }}</span>
      </template>
    </v-slider>

    <v-btn
      v-if="overviews.length && timestamp"
      fab small
      absolute bottom left
      @click="play"
    ><v-icon>play_arrow</v-icon></v-btn>

  </v-container>
</template>

<script>
  import { OVERVIEWS } from '@/jsons/settings.json'
  import L from '@/modules/leaflet.js'
  import STATIONS from '@/jsons/stations.json'
  import Tab1Markers from '@/components/Tab1Markers.vue'
  import Tab1Legend from '@/components/Tab1Legend.vue'

  export default {
    components: { Tab1Markers, Tab1Legend },
    data() {
      return {
        map: null,
        selected: 'temp',
        zoom: 4,
        timestamp: 0,
        tileName: null,
        bounds: {lat: [0, 0], lng: [0, 0]},
        isPlaying: false,
      }
    },
    computed: {
      // 項目一覧
      selectOptions() {
        return _.map(OVERVIEWS, setting => { return {id: setting.id, label: setting.label[this.lang]} })
      },
      // 日本全体
      stations4bounds() {
        return _.filter(STATIONS, station => { return station.id !== '44356' })
      },
    },
    async mounted() {
      // 地図描画（setTimeoutは本来いらないが、このタブから開かれた時対策）
      setTimeout(() => {
        this.map = L.draw({
          id: 'mapOverview',
          zoom: this.zoom,
          tileLayer: false,
          zoomControl: false,
        })
        this.map.addMapboxTiles(this.getTileName()) // timestampによらず必ず実行
        this.addEvent()
        this.map.fitBounds(_.map(this.stations4bounds, station => { return station.coords }))
      }, 0)
    },
    watch: {
      // 概況一覧変更
      overviews: {
        immediate: true,
        handler() {
          if (this.overviews.length) {
            this.timestamp = _.last(this.overviews).timestamp
          }
        }
      },
      // タイル名変更
      tileName() {
        if (this.map) {
          this.map.addMapboxTiles(this.tileName)
        }
      },
      // 時刻変更
      timestamp() {
        this.tileName = this.getTileName()
      },
    },
    methods: {
      // タイル名取得
      getTileName() {
        const hour = this.$dayjs(this.timestamp).hour()
        return hour > 19 ? 'night' : hour > 16 ? 'sunset' : hour > 8 ? 'day' : hour > 5 ? 'sunset' : 'night'
      },
      // 再生
      play() {
        this.isPlaying = true
        // 再生開始
        setTimeout(() => {
          let index = 0
          const interval = setInterval(() => {
            this.timestamp = this.overviews[index].timestamp
            index++
            if (index >= this.overviews.length - 1) {
              clearInterval(interval)
              this.isPlaying = false
            }
          }, 100)
        }, 0)
      },
      // イベント付与
      addEvent() {
        this.map.on('zoom', () => {
          this.zoom = _.round(this.map.getZoom())
        })
        this.map.on('moveend', () => {
          const northEast = this.map.getBounds().getNorthEast()
          const southWest = this.map.getBounds().getSouthWest()
          this.bounds = {lat: [northEast.lat, southWest.lat], lng: [northEast.lng, southWest.lng]}
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .container {
    padding: 0;
    position: relative;
  }
  #mapOverview {
    height: 100%;
    width: 100%;
    z-index: 0;
  }
  .v-select {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 150px;
    ::v-deep .v-text-field__details {
      display: none;
    }
  }
  .v-input__slider {
    position: absolute;
    bottom: 3px;
    left: 64px;
    width: calc(100% - calc(64px + 80px));
    ::v-deep .v-slider__thumb-label {
      line-height: 1.2;
      text-align: center;
      .time {
        font-size: 1.2em;
      }
    }
    ::v-deep .v-messages {
      display: none;
    }
  }
  .v-btn {
    bottom: 8px !important;
  }
</style>