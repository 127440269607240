import { nanoid } from 'nanoid'

const VER = 3.0

export default Object.freeze({
  APP_ID: 'amedas',
  VER: VER,
  LOCAL_DATA_STRUCTURE: {
    ver: VER,
    activated: new Date().getTime(),
    userId: nanoid(10),
    isAdmin: false,
    data: {
      theme: 'system', // 'system', 'dark', 'light'
      favorites: [14163, 44132, 62078, 91197, 94011], // お気に入り観測所
      recentList: ['temp', 'precipitation1h', 'windDirection', 'wind', 'sun1h', 'humidity', 'pressure', 'snow'], // 一覧表示項目 'pressure','snow'
    },
  },
  ROOT_LANGUAGE: 'ja', // ja or en
  STORE_STATE: {
    overview: [],
    overviews: [],
    rankings: [],
    forecasts: [],
    sortting: false,
    labelDialog: false,
    textExample: 'Test',
    stationId: null, // target station id
    stationTabId: 'stationTab0'
  },
  FORM_ID: 128,
  URLS: {
    pwa: 'https://base.aroundit.net',
    play: 'https://play.google.com/store/apps/details?id=net.aroundit.amedas',
    app: 'https://apps.apple.com/jp/app/weather-observations-japan/id1178829402',
    privacy: 'https://apps.wanakijiji.com/wp-json/wp/v2/pages/557',
    request: 'https://apps.wanakijiji.com/wp-json/wp/v2/pages/128',
  },
  API_KEY: {
    admob: {
      banner: {
        android: 'ca-app-pub-1693924705262886/6874377663',
        ios: 'ca-app-pub-1693924705262886/8115608465',
        },
      interstitial: {
        android: 'ca-app-pub-1693924705262886/9294167364',
        ios: 'ca-app-pub-1693924705262886/6271885376',
      },
    },
    gcp: {
      web: "AIzaSyD-Abc8xJyO8r8H1UMjglCTc4qqiP6Gjaw",
      android: 'AIzaSyACD9JR0iGsFGRsyef6PegFWvO7rg6qW2s', // 要対応（制限して！）
      ios: 'AIzaSyARMlEaOT655QdRaOr4_tsJnvqpvvsfMSs',
    }
  },
  FIREBASE: {
    apiKey: "AIzaSyD-Abc8xJyO8r8H1UMjglCTc4qqiP6Gjaw",
    authDomain: "amedas-2020.firebaseapp.com",
    databaseURL: "https://amedas-2020.firebaseio.com",
    projectId: "amedas-2020",
    storageBucket: "amedas-2020.appspot.com",
    messagingSenderId: "188476373874",
    appId: "1:188476373874:web:b1fcbda4b7bafad033fbeb",
    measurementId: "G-SHKWC5X56K"
  },
  SOURCE: {
    name: {
      ja: 'アメダス｜気象庁',
      en: 'JMA',
    },
    url: {
      ja: 'https://www.jma.go.jp/bosai/map.html#5/34.5/137/&elem=temp&contents=amedas&interval=60',
      en: 'https://www.jma.go.jp/bosai/map.html#5/34.5/137/&elem=temp&contents=amedas&lang=en&interval=60',
    },
  },
  // 表示メニュー一覧
  MENU: {
    pcOpen: true, // pc時は常に表示
    order: ['home', 'setting', 'purchase', 'request', 'contact', 'review', 'privacy', 'admin'], // 'home', 'request', 'contact', 'review', 'language'
    extras: [], // item objectを入れて
  },
  COLOR: {
    light: {
      primary: '#0d47a1',
      secondary: '#F57C00',
      accent: '#ff9800',
      error: '#ff5722',
      warning: '#f44336',
      info: '#607d8b',
      success: '#8bc34a',
      statusBar: '#f5f5f5',
      background: '#F5F5F5',
    },
    dark: {
      primary: '#1976D2',
      secondary: '#F57C00',
      accent: '#ff9800',
      error: '#ff5722',
      warning: '#f44336',
      info: '#607d8b',
      success: '#8bc34a',
      statusBar: '#1E1E1E',
      background: '#121212',
    }
  },
  TICKETS: [],
})
