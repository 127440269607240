<template>
  <div></div>
</template>

<script>
  import axios from 'axios'
  import dayjs from 'dayjs'
  import STATIONS from '@/jsons/stations.json'
  const STATIONS_BY_ID = {}
  _.forEach(STATIONS, station => {
    STATIONS_BY_ID[station.id] = station
  })

  export default {
    data() {
      return {
        updatedAt: 0,
      }
    },
    async created() {
      await this.renewOverview()
      this.renewForecasts()
      this.renewRankings()
    },
    async mounted() {
      setTimeout(() => {
        this.renewOverviews()
      }, 0)
    },
    methods: {
      // forecasts
      async renewForecasts() {
        const res = await axios.get('https://data.aroundit.net/caches/forecast.json.gz?timestamp=' + dayjs().valueOf()).catch(err => {console.log(err) })
        this.forecasts = res ? Object.freeze(res.data) : this.forecasts
        console.log('forecasts', this.forecasts)
      },
      // rankings
      async renewRankings() {
        const res = await axios.get('https://data.aroundit.net/caches/ranking.json.gz?timestamp=' + dayjs().valueOf()).catch(err => {console.log(err) })
        this.rankings = res ? Object.freeze(res.data) : this.rankings
        console.log('rankings', this.rankings)
      },
      // overview
      async renewOverview() {
        const res = await axios.get('https://data.aroundit.net/overview/latest.json.gz?timestamp=' + dayjs().valueOf()).catch(err => {console.log(err) })
        this.overview = res ? Object.freeze(_.map(res.data.overview, station => {
          return _.assign(station, _.find(STATIONS, ['id', station.id]))
        })) : this.overview
        console.log('overview', this.overview)
      },
      // overviews
      async renewOverviews() {
        const thisHour = dayjs().subtract(14, 'minute').startOf('hour')
        const before24hours = dayjs(thisHour.valueOf()).subtract(24, 'hour')
        const names = _.map(_.range(24), index => {
          const time = dayjs(before24hours.valueOf()).add(1 * index, 'hour')
          return time.format('YYYYMMDDHHmm')
        })
        let overviews = []
        for (const name of names) {
          const res = await axios.get('https://data.aroundit.net/overview/' + name + '.json.gz?timestamp=' + dayjs().valueOf()).catch(err => {console.log(err) })
          overviews = res ? _.concat(overviews, res.data) : overviews
        }
        _.forEach(overviews, overview => {
          _.forEach(overview.overview, (station, index) => {
            overview.overview[index] = _.assign(overview.overview[index], STATIONS_BY_ID[station.id])
          })
        })
        this.overviews = Object.freeze(_.sortBy(overviews, 'timestamp'))
        console.log('overviews', this.overviews)
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>
