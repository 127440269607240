import INIT from '../init.js'
import Vue from 'vue'
import _store from '../commons/modules/_store'
import _mixin from '../commons/modules/_mixin'

const data = {}
const computed = {}
const methods = {}

// computed for store
const computed4store = (() => {
  const computed4store = {}
  const state = Object.assign({}, _store.state, INIT.STORE_STATE)
  _.forEach(state, (init, key) => {
    computed4store[key] = {
      get() { return this.$store.state[key] },
      set(value) { this.$store.commit('set' + _.capitalize(key), value) }
    }
  })
  return computed4store
})()
// computed for appData
const computed4appData = (() => {
  const computed4appData = {}
  _.forEach(INIT.LOCAL_DATA_STRUCTURE.data, (init, key) => {
    computed4appData[key] = {
      get() { return _.cloneDeep(_.get(this.storage.data, key)) },
      set(value) {
        const appData = _.cloneDeep(this.storage.data)
        appData[key] = value
        this.storage = Object.assign({}, this.storage, {data: appData})
      }
    }
  })
  return computed4appData
})()

Vue.mixin({
  data: () => (Object.assign(_mixin.data, data)),
  computed: Object.assign(_mixin.computed, computed4store, computed4appData, computed),
  methods: Object.assign(_mixin.methods, methods),
})
