<template>
  <div></div>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
    computed: {
    },
  }
</script>

<style lang="scss" scoped>
</style>
