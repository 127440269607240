<template>
  <fragment>

    <TheAppbar :title="$t('title')"></TheAppbar>

    <v-main>
      <v-container>
        <v-list>
          <!-- Theme -->
          <v-list-item
            @click="inputDialog = inputTheme"
          >
            <v-list-item-content>
              <v-list-item-title>{{ inputTheme.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ _.find(inputTheme.options, ['id', theme]).label }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset />
          <!-- text -->
          <v-list-item
            @click="inputDialog = inputText"
          >
            <v-list-item-content>
              <v-list-item-title>{{ inputText.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ textExample }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset />
          <!-- switch -->
          <v-list-item
            @click="switch1 = ! switch1"
          >
            <v-list-item-content>
              <v-list-item-title>{{ switchExample.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ switchExample.desc }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch
                v-model="switch1"
                label=""
              ></v-switch>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-container>
    </v-main>

    <TheInputDialog />

  </fragment>
</template>

<script>
  import TheAppbar from '../commons/components/TheAppbar.vue'
  import TheInputDialog from '../commons/components/TheInputDialog.vue'

  export default {
    data() {
      return {
        // テーマ
        inputTheme: {
          title: this.$t('theme.theme'),
          type: 'radio',
          model: 'theme',
          options: [
            {id: 'light', label: this.$t('theme.light')},
            {id: 'dark', label: this.$t('theme.dark')},
            {id: 'system', label: this.$t('theme.system')}
          ],
        },
        // text example
        inputText: {
          title: 'Text Example',
          type: 'text',
          model: 'textExample',
        },
        // switch example
        switchExample: {
          title: 'Switch Example',
          desc: 'Switch Example Description',
        },
        switch1: true,
      }
    },
    computed: {},
    methods: {},
    i18n: {
      messages: {
        ja: {
          title: '設定',
        },
        en: {
          title: 'Setting',
        }
      }
    },
    components: { TheAppbar, TheInputDialog },
  }
</script>

<style lang="scss" scoped>
  .v-list-item__content {
    padding: 16px 0;
  }
  .v-list-item__title {
    font-size: 1.2rem;
  }
  .v-list-item__subtitle {
    font-size: 1rem;
  }
</style>
