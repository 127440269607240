import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import _leaflet2 from '../commons/modules/_leaflet2'

export default L

// L拡張
L.draw = (options) => {
  options = Object.assign({
    id: 'map', zoom: 15, center: {lat: 35.7052579, lng: 139.752536},
    attributionControl: false, zoomControl: true,
    tileLayer: true, // openstreetmap
    tapHold: true, // contextmenuとかぶってない？
    inertia: true, // 慣性
    preferCanvas: true, // canvasで描画
  }, options)
  const map = L.map(document.getElementById(options.id), options)
  const tileLayer = options.tileLayer ? L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18 }).addTo(map) : null
  return _.assign(map, customMethods(map, tileLayer))
}

// Arrow marker
L.Canvas.include({
  _updateArrowMarker: function(layer) {
    if (!this._drawing || layer._empty()) { return }
    const p = layer._point
    const ctx = this._ctx
    const ratio = layer._radius / 24
    // 基準点
    const x = p.x - (12 * ratio)
    const y = p.y - (12 * ratio)
    // save
    ctx.save()
    // 回転
    ctx.translate(p.x, p.y)
    ctx.rotate((Math.PI / 180) * layer.options.rotate)
    ctx.translate(-p.x, -p.y)
    // 描画
    ctx.beginPath()
    ctx.moveTo(x + 0, y + 0)
    ctx.lineTo(x + (18 * ratio), y + 0)
    ctx.lineTo(x + (18 * ratio), y + (18 * ratio))
    ctx.lineTo(x + 0, y + (18 * ratio))
    ctx.closePath()
    ctx.beginPath()
    ctx.moveTo(x + 0, y + 0)
    ctx.lineTo(x + (24 * ratio), y + 0)
    ctx.lineTo(x + (24 * ratio), y + (24 * ratio))
    ctx.lineTo(x + 0, y + (24 * ratio))
    ctx.lineTo(x + 0, y + 0)
    ctx.closePath()
    ctx.beginPath()
    ctx.moveTo(x + (12 * ratio), y + (2 * ratio))
    ctx.lineTo(x + (4.5 * ratio), y + (20.29 * ratio))
    ctx.lineTo(x + (5.21 * ratio), y + (21 * ratio))
    ctx.lineTo(x + (12 * ratio), y + (18 * ratio))
    ctx.lineTo(x + (18.79 * ratio), y + (21 * ratio))
    ctx.lineTo(x + (19.5 * ratio), y + (20.29 * ratio))
    ctx.lineTo(x + (12 * ratio), y + (2 * ratio))
    ctx.closePath()
    // restore
    ctx.restore()
    // 塗りつぶし
    this._fillStroke(ctx, layer)
  },
})
L.ArrowMarker = L.CircleMarker.extend({
  _updatePath: function() {
    this._renderer._updateArrowMarker(this)
  },
})


// map拡張
const customMethods = (map, tileLayer) => {

  _leaflet2.map = map
  _leaflet2.tileLayer = tileLayer

  return _.assign({}, _leaflet2, {

    // mapbox tiles
    addMapboxTiles(tileName) { // day, night, sunset
      tileName = tileName || 'day'
      if (! this.map) { return }
      const tile = {
        day: 'cl382uokg000p16li25bemm0a',
        night: 'cl382mogb000o16litfjnfh7p',
        sunset: 'cl3831sos001115nwl25vpfl5',
      }
      const xTileLayer = this.tileLayer
      this.tileLayer = L.tileLayer('https://api.mapbox.com/styles/v1/shiggy/' + tile[tileName] + '/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic2hpZ2d5IiwiYSI6ImNqbHV3cGcxdjBueGozcW9kd2x2cXBocmYifQ.0PpPputD2ZZkh77kk3CSig').addTo(this.map)
      setTimeout(() => {
        if (xTileLayer) {
          this.map.removeLayer(xTileLayer)
        }
      }, 500)
    },
  
  })
}
